<template>
  <div class="container">
    <div class="container_cityTour_box">
      <div class="header_type_box">
        <div
          class="type_item"
          v-for="(item, index) in typeList"
          :key="index"
          @click="onClickNumber(index)"
          :class="{ type_item_active: currentIndex == index }"
        >
          {{ item }}
        </div>
      </div>

      <!-- 列表 -->
      <div class="list_box">
        <div class="list_item">
          <div style="font-size: 0">
            <img
              src="../../../assets/news/xiamen.png"
              alt=""
              class="list_icon"
            />
          </div>
          <div class="list_city">杭州</div>
          <div class="list_city_descip">绝不负江南绝不负江南</div>
        </div>
        <div class="list_item">
          <div style="font-size: 0">
            <img
              src="../../../assets/news/xiamen.png"
              alt=""
              class="list_icon"
            />
          </div>
          <div class="list_city">杭州</div>
          <div class="list_city_descip">绝不负江南绝不负江南</div>
        </div>
        <div class="list_item">
          <div style="font-size: 0">
            <img
              src="../../../assets/news/xiamen.png"
              alt=""
              class="list_icon"
            />
          </div>
          <div class="list_city">杭州</div>
          <div class="list_city_descip">绝不负江南绝不负江南</div>
        </div>
        <div class="list_item">
          <div style="font-size: 0">
            <img
              src="../../../assets/news/xiamen.png"
              alt=""
              class="list_icon"
            />
          </div>
          <div class="list_city">杭州</div>
          <div class="list_city_descip">绝不负江南绝不负江南</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeList: ["时令旅游", "吃货基地", "赏景圣地"],
      currentIndex: 0,
    };
  },
  methods: {
    onClickNumber(val) {
      this.currentIndex = val;
    },
  },
};
</script>

<style scoped>
.container_cityTour_box {
  margin: 0 0px 30px;
}
.header_type_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 42px;
  margin: 0 30px;
}
.type_item {
  background-color: #fff !important;
  width: 214px;
  height: 58px;
  text-align: center;
  line-height: 58px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 30px;
  color: #303030;
}
.type_item_active {
  background: url(../../../assets/news/short_btn.png) no-repeat;
  background-size: 100% 100%;
  width: 214px;
  height: 58px;
  text-align: center;
  line-height: 58px;
  font-weight: 600;
  font-size: 30px;
  color: #fff;
}
.list_item {
  width: 210px;
  height: 288px;
  /* border: 1px solid red; */
  margin-bottom: 30px;
  margin-left: 30px;
}
.list_icon {
  width: 210px;
  height: 176px;
  border-radius: 20px;
}
.list_city {
  font-weight: 600;
  font-size: 32px;
  color: #303030;
  height: 44px;
  margin: 18px 0 6px;
}
.list_city_descip {
  font-weight: 400;
  font-size: 24px;
  color: #9f9f9f;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
