<template>
  <div class="recommend_header">
    <div class="recommend-box">
      <div class="nominate-box">
        <div
          class="nominate-item"
          v-for="(item, index) in recommList"
          :key="index"
          @click="onChangeNumber(index)"
          :class="{ 'nominate-item-item': currentIndex == index }"
        >
          {{ item }}
          <img
            src="../../../assets/home/role.png"
            alt=""
            class="role_icon"
            v-show="currentIndex == index"
          />
        </div>
      </div>
    </div>
    <!-- 推荐景点 -->
    <div class="block_one_demo" v-if="currentIndex == 0">
      <div class="spoint_box">
        <div class="spoint_left">
          <img src="" alt="" class="spoint_icon" />
          <div class="introduce_box">63处讲解</div>
        </div>
        <div class="spoint_right">
          <div class="spoint_right_header">
            <div class="spoint_text">北京天坛公园</div>
            <img src="../../../assets/home/5A.png" alt="" class="leve-icon" />
          </div>
          <div class="spoint_location_left">
            <img
              src="../../../assets/home/dizhi.png"
              alt=""
              class="dizhi_icon"
            />
            <span class="location_city">北京</span>
            <div class="line"></div>
            <span class="location_city">陆离去哦12.6km</span>
          </div>

          <div class="eye_box">
            <img src="../../../assets/home/eye.png" alt="" class="eye_icon" />
            <span class="location_city">33</span>
          </div>
        </div>
      </div>
      <div class="spoint_box">
        <div class="spoint_left">
          <img src="" alt="" class="spoint_icon" />
          <div class="introduce_box">63处讲解</div>
        </div>
        <div class="spoint_right">
          <div class="spoint_right_header">
            <div class="spoint_text">北京天坛公园</div>
            <img src="../../../assets/home/5A.png" alt="" class="leve-icon" />
          </div>
          <div class="spoint_location_left">
            <img
              src="../../../assets/home/dizhi.png"
              alt=""
              class="dizhi_icon"
            />
            <span class="location_city">北京</span>
            <div class="line"></div>
            <span class="location_city">陆离去哦12.6km</span>
          </div>

          <div class="eye_box">
            <img src="../../../assets/home/eye.png" alt="" class="eye_icon" />
            <span class="location_city">33</span>
          </div>
        </div>
      </div>
      <div class="spoint_box">
        <div class="spoint_left">
          <img src="" alt="" class="spoint_icon" />
          <div class="introduce_box">63处讲解</div>
        </div>
        <div class="spoint_right">
          <div class="spoint_right_header">
            <div class="spoint_text">北京天坛公园</div>
            <img src="../../../assets/home/5A.png" alt="" class="leve-icon" />
          </div>
          <div class="spoint_location_left">
            <img
              src="../../../assets/home/dizhi.png"
              alt=""
              class="dizhi_icon"
            />
            <span class="location_city">北京</span>
            <div class="line"></div>
            <span class="location_city">陆离去哦12.6km</span>
          </div>

          <div class="eye_box">
            <img src="../../../assets/home/eye.png" alt="" class="eye_icon" />
            <span class="location_city">33</span>
          </div>
        </div>
      </div>
      <div class="spoint_box">
        <div class="spoint_left">
          <img src="" alt="" class="spoint_icon" />
          <div class="introduce_box">63处讲解</div>
        </div>
        <div class="spoint_right">
          <div class="spoint_right_header">
            <div class="spoint_text">北京天坛公园</div>
            <img src="../../../assets/home/5A.png" alt="" class="leve-icon" />
          </div>
          <div class="spoint_location_left">
            <img
              src="../../../assets/home/dizhi.png"
              alt=""
              class="dizhi_icon"
            />
            <span class="location_city">北京</span>
            <div class="line"></div>
            <span class="location_city">陆离去哦12.6km</span>
          </div>

          <div class="eye_box">
            <img src="../../../assets/home/eye.png" alt="" class="eye_icon" />
            <span class="location_city">33</span>
          </div>
        </div>
      </div>
      <div class="spoint_box">
        <div class="spoint_left">
          <img src="" alt="" class="spoint_icon" />
          <div class="introduce_box">63处讲解</div>
        </div>
        <div class="spoint_right">
          <div class="spoint_right_header">
            <div class="spoint_text">北京天坛公园</div>
            <img src="../../../assets/home/5A.png" alt="" class="leve-icon" />
          </div>
          <div class="spoint_location_left">
            <img
              src="../../../assets/home/dizhi.png"
              alt=""
              class="dizhi_icon"
            />
            <span class="location_city">北京</span>
            <div class="line"></div>
            <span class="location_city">陆离去哦12.6km</span>
          </div>

          <div class="eye_box">
            <img src="../../../assets/home/eye.png" alt="" class="eye_icon" />
            <span class="location_city">33</span>
          </div>
        </div>
      </div>
      <div class="spoint_box">
        <div class="spoint_left">
          <img src="" alt="" class="spoint_icon" />
          <div class="introduce_box">63处讲解</div>
        </div>
        <div class="spoint_right">
          <div class="spoint_right_header">
            <div class="spoint_text">北京天坛公园</div>
            <img src="../../../assets/home/5A.png" alt="" class="leve-icon" />
          </div>
          <div class="spoint_location_left">
            <img
              src="../../../assets/home/dizhi.png"
              alt=""
              class="dizhi_icon"
            />
            <span class="location_city">北京</span>
            <div class="line"></div>
            <span class="location_city">陆离去哦12.6km</span>
          </div>

          <div class="eye_box">
            <img src="../../../assets/home/eye.png" alt="" class="eye_icon" />
            <span class="location_city">33</span>
          </div>
        </div>
      </div>
      <div class="spoint_box">
        <div class="spoint_left">
          <img src="" alt="" class="spoint_icon" />
          <div class="introduce_box">63处讲解</div>
        </div>
        <div class="spoint_right">
          <div class="spoint_right_header">
            <div class="spoint_text">北京天坛公园</div>
            <img src="../../../assets/home/5A.png" alt="" class="leve-icon" />
          </div>
          <div class="spoint_location_left">
            <img
              src="../../../assets/home/dizhi.png"
              alt=""
              class="dizhi_icon"
            />
            <span class="location_city">北京</span>
            <div class="line"></div>
            <span class="location_city">陆离去哦12.6km</span>
          </div>

          <div class="eye_box">
            <img src="../../../assets/home/eye.png" alt="" class="eye_icon" />
            <span class="location_city">33</span>
          </div>
        </div>
      </div>
      <div class="spoint_box">
        <div class="spoint_left">
          <img src="" alt="" class="spoint_icon" />
        </div>
        <div class="spoint_right">
          <div class="spoint_right_header">
            <div class="spoint_text">北京天坛公园</div>
            <img src="../../../assets/home/5A.png" alt="" class="leve-icon" />
          </div>
          <div class="spoint_location_left">
            <img
              src="../../../assets/home/dizhi.png"
              alt=""
              class="dizhi_icon"
            />
            <span class="location_city">北京</span>
            <div class="line"></div>
            <span class="location_city">陆离去哦12.6km</span>
          </div>

          <div class="eye_box">
            <img src="../../../assets/home/eye.png" alt="" class="eye_icon" />
            <span class="location_city">33</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 好玩目的地 -->
    <destinationsItemVue v-if="currentIndex == 1"></destinationsItemVue>
  </div>
</template>

<script>
import destinationsItemVue from "./destinationsItem.vue";
export default {
  components: {
    destinationsItemVue,
  },
  data() {
    return {
      recommList: ["推荐景点", "好玩目的地", "网红打卡地"],
      currentIndex: 0,
    };
  },
  methods: {
    onChangeNumber(index) {
      this.currentIndex = index;
    },
  },
};
</script>

<style scoped>
.recommend-box {
  margin: 48px 30px 38px;
  /* border: 1px solid red; */
}
.nominate-box {
  display: flex;
  align-items: center;
}
.nominate-item {
  height: 60px;
  font-weight: 600;
  font-size: 32px;
  color: #a6a6a6;
  line-height: 44px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.role_icon {
  width: 56px;
  height: 18px;
}
.nominate-item-item {
  height: 60px;
  font-weight: 600;
  font-size: 32px;
  color: #303030;
  line-height: 44px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.spoint_box {
  height: 196px;
  background: #ffffff;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  margin: 0 30px 20px;
  overflow: hidden;
  display: flex;
}
.spoint_left {
  width: 235px;
  height: 176px;
  background: pink;
  border-radius: 26px;
  margin: 10px 40px 10px 10px;
  position: relative;
}
.spoint_icon {
  width: 100%;
  height: 100%;
  border-radius: 26px;
}
.leve-icon {
  width: 30px;
  height: 34px;
}
.spoint_right_header {
  display: flex;
  align-items: center;
  margin: 20px 0 28px;
}
.spoint_text {
  max-width: 224px;
  height: 44px;
  font-weight: 600;
  font-size: 32px;
  color: #303030;
  line-height: 44px;
  margin-right: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dizhi_icon {
  width: 22px;
  height: 24px;
  margin-right: 10px;
}
.location_city {
  height: 34px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 26px;
  color: #9f9f9f;
  line-height: 34px;
}
.spoint_location_left {
  display: flex;
  align-items: center;
}
.line {
  width: 3px;
  height: 28px;
  background: #999;
  margin: 0 12px;
}
.eye_icon {
  width: 29px;
  height: 20px;
  margin-right: 10px;
}
.introduce_box {
  position: absolute;
  top: 0;
  width: 140px;
  height: 38px;
  background: #000000;
  border-radius: 20px 0px 21px 0px;
  opacity: 0.5;
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.eye_box {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
</style>
