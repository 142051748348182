import axios from "axios";
export default {
  namespaced: true,
  state: () => ({
    wxJsapid: localStorage.getItem('wxJsapid') || ''
    
  }),
  mutations: {
    GET_WXJSAPID(state,newWxJsapid) {
      state.wxJsapid = newWxJsapid
      localStorage.setItem('wxJsapid', newWxJsapid); // 将新值存储到本地存储中
    },
   
  },
  actions: {
    /**
     *  获取微信appid
     */
    async useWxJsapid({ commit }) {
      const { data } = await axios .get("https://vip.nc10086.com/api/chywxgzhconfig/chyWxGzhConfig/noauth/getEnabledAppId")
      console.log(data)
      commit('GET_WXJSAPID', data.result);
    },
   
  }
}
