var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recommend_header"},[_c('div',{staticClass:"recommend-box"},[_c('div',{staticClass:"nominate-box"},_vm._l((_vm.recommList),function(item,index){return _c('div',{key:index,staticClass:"nominate-item",class:{ 'nominate-item-item': _vm.currentIndex == index },on:{"click":function($event){return _vm.onChangeNumber(index)}}},[_vm._v(" "+_vm._s(item)+" "),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex == index),expression:"currentIndex == index"}],staticClass:"role_icon",attrs:{"src":require("../../../assets/home/role.png"),"alt":""}})])}),0)]),(_vm.currentIndex == 0)?_c('div',{staticClass:"block_one_demo"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)]):_vm._e(),(_vm.currentIndex == 1)?_c('destinationsItemVue'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spoint_box"},[_c('div',{staticClass:"spoint_left"},[_c('img',{staticClass:"spoint_icon",attrs:{"src":"","alt":""}}),_c('div',{staticClass:"introduce_box"},[_vm._v("63处讲解")])]),_c('div',{staticClass:"spoint_right"},[_c('div',{staticClass:"spoint_right_header"},[_c('div',{staticClass:"spoint_text"},[_vm._v("北京天坛公园")]),_c('img',{staticClass:"leve-icon",attrs:{"src":require("../../../assets/home/5A.png"),"alt":""}})]),_c('div',{staticClass:"spoint_location_left"},[_c('img',{staticClass:"dizhi_icon",attrs:{"src":require("../../../assets/home/dizhi.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("北京")]),_c('div',{staticClass:"line"}),_c('span',{staticClass:"location_city"},[_vm._v("陆离去哦12.6km")])]),_c('div',{staticClass:"eye_box"},[_c('img',{staticClass:"eye_icon",attrs:{"src":require("../../../assets/home/eye.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("33")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spoint_box"},[_c('div',{staticClass:"spoint_left"},[_c('img',{staticClass:"spoint_icon",attrs:{"src":"","alt":""}}),_c('div',{staticClass:"introduce_box"},[_vm._v("63处讲解")])]),_c('div',{staticClass:"spoint_right"},[_c('div',{staticClass:"spoint_right_header"},[_c('div',{staticClass:"spoint_text"},[_vm._v("北京天坛公园")]),_c('img',{staticClass:"leve-icon",attrs:{"src":require("../../../assets/home/5A.png"),"alt":""}})]),_c('div',{staticClass:"spoint_location_left"},[_c('img',{staticClass:"dizhi_icon",attrs:{"src":require("../../../assets/home/dizhi.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("北京")]),_c('div',{staticClass:"line"}),_c('span',{staticClass:"location_city"},[_vm._v("陆离去哦12.6km")])]),_c('div',{staticClass:"eye_box"},[_c('img',{staticClass:"eye_icon",attrs:{"src":require("../../../assets/home/eye.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("33")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spoint_box"},[_c('div',{staticClass:"spoint_left"},[_c('img',{staticClass:"spoint_icon",attrs:{"src":"","alt":""}}),_c('div',{staticClass:"introduce_box"},[_vm._v("63处讲解")])]),_c('div',{staticClass:"spoint_right"},[_c('div',{staticClass:"spoint_right_header"},[_c('div',{staticClass:"spoint_text"},[_vm._v("北京天坛公园")]),_c('img',{staticClass:"leve-icon",attrs:{"src":require("../../../assets/home/5A.png"),"alt":""}})]),_c('div',{staticClass:"spoint_location_left"},[_c('img',{staticClass:"dizhi_icon",attrs:{"src":require("../../../assets/home/dizhi.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("北京")]),_c('div',{staticClass:"line"}),_c('span',{staticClass:"location_city"},[_vm._v("陆离去哦12.6km")])]),_c('div',{staticClass:"eye_box"},[_c('img',{staticClass:"eye_icon",attrs:{"src":require("../../../assets/home/eye.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("33")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spoint_box"},[_c('div',{staticClass:"spoint_left"},[_c('img',{staticClass:"spoint_icon",attrs:{"src":"","alt":""}}),_c('div',{staticClass:"introduce_box"},[_vm._v("63处讲解")])]),_c('div',{staticClass:"spoint_right"},[_c('div',{staticClass:"spoint_right_header"},[_c('div',{staticClass:"spoint_text"},[_vm._v("北京天坛公园")]),_c('img',{staticClass:"leve-icon",attrs:{"src":require("../../../assets/home/5A.png"),"alt":""}})]),_c('div',{staticClass:"spoint_location_left"},[_c('img',{staticClass:"dizhi_icon",attrs:{"src":require("../../../assets/home/dizhi.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("北京")]),_c('div',{staticClass:"line"}),_c('span',{staticClass:"location_city"},[_vm._v("陆离去哦12.6km")])]),_c('div',{staticClass:"eye_box"},[_c('img',{staticClass:"eye_icon",attrs:{"src":require("../../../assets/home/eye.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("33")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spoint_box"},[_c('div',{staticClass:"spoint_left"},[_c('img',{staticClass:"spoint_icon",attrs:{"src":"","alt":""}}),_c('div',{staticClass:"introduce_box"},[_vm._v("63处讲解")])]),_c('div',{staticClass:"spoint_right"},[_c('div',{staticClass:"spoint_right_header"},[_c('div',{staticClass:"spoint_text"},[_vm._v("北京天坛公园")]),_c('img',{staticClass:"leve-icon",attrs:{"src":require("../../../assets/home/5A.png"),"alt":""}})]),_c('div',{staticClass:"spoint_location_left"},[_c('img',{staticClass:"dizhi_icon",attrs:{"src":require("../../../assets/home/dizhi.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("北京")]),_c('div',{staticClass:"line"}),_c('span',{staticClass:"location_city"},[_vm._v("陆离去哦12.6km")])]),_c('div',{staticClass:"eye_box"},[_c('img',{staticClass:"eye_icon",attrs:{"src":require("../../../assets/home/eye.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("33")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spoint_box"},[_c('div',{staticClass:"spoint_left"},[_c('img',{staticClass:"spoint_icon",attrs:{"src":"","alt":""}}),_c('div',{staticClass:"introduce_box"},[_vm._v("63处讲解")])]),_c('div',{staticClass:"spoint_right"},[_c('div',{staticClass:"spoint_right_header"},[_c('div',{staticClass:"spoint_text"},[_vm._v("北京天坛公园")]),_c('img',{staticClass:"leve-icon",attrs:{"src":require("../../../assets/home/5A.png"),"alt":""}})]),_c('div',{staticClass:"spoint_location_left"},[_c('img',{staticClass:"dizhi_icon",attrs:{"src":require("../../../assets/home/dizhi.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("北京")]),_c('div',{staticClass:"line"}),_c('span',{staticClass:"location_city"},[_vm._v("陆离去哦12.6km")])]),_c('div',{staticClass:"eye_box"},[_c('img',{staticClass:"eye_icon",attrs:{"src":require("../../../assets/home/eye.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("33")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spoint_box"},[_c('div',{staticClass:"spoint_left"},[_c('img',{staticClass:"spoint_icon",attrs:{"src":"","alt":""}}),_c('div',{staticClass:"introduce_box"},[_vm._v("63处讲解")])]),_c('div',{staticClass:"spoint_right"},[_c('div',{staticClass:"spoint_right_header"},[_c('div',{staticClass:"spoint_text"},[_vm._v("北京天坛公园")]),_c('img',{staticClass:"leve-icon",attrs:{"src":require("../../../assets/home/5A.png"),"alt":""}})]),_c('div',{staticClass:"spoint_location_left"},[_c('img',{staticClass:"dizhi_icon",attrs:{"src":require("../../../assets/home/dizhi.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("北京")]),_c('div',{staticClass:"line"}),_c('span',{staticClass:"location_city"},[_vm._v("陆离去哦12.6km")])]),_c('div',{staticClass:"eye_box"},[_c('img',{staticClass:"eye_icon",attrs:{"src":require("../../../assets/home/eye.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("33")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spoint_box"},[_c('div',{staticClass:"spoint_left"},[_c('img',{staticClass:"spoint_icon",attrs:{"src":"","alt":""}})]),_c('div',{staticClass:"spoint_right"},[_c('div',{staticClass:"spoint_right_header"},[_c('div',{staticClass:"spoint_text"},[_vm._v("北京天坛公园")]),_c('img',{staticClass:"leve-icon",attrs:{"src":require("../../../assets/home/5A.png"),"alt":""}})]),_c('div',{staticClass:"spoint_location_left"},[_c('img',{staticClass:"dizhi_icon",attrs:{"src":require("../../../assets/home/dizhi.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("北京")]),_c('div',{staticClass:"line"}),_c('span',{staticClass:"location_city"},[_vm._v("陆离去哦12.6km")])]),_c('div',{staticClass:"eye_box"},[_c('img',{staticClass:"eye_icon",attrs:{"src":require("../../../assets/home/eye.png"),"alt":""}}),_c('span',{staticClass:"location_city"},[_vm._v("33")])])])])
}]

export { render, staticRenderFns }