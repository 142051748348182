<template>
  <div class="container_box">
    <!-- 头部 -->
    <div class="hader_stick">
      <div class="container-header-icon">
        <div class="location_header">
          <div class="location_header_left" @click="navCity">
            <img
              src="../assets/home/location.png"
              alt=""
              class="location_icon"
            />
            <span class="location_text">北京</span>
          </div>
          <div class="location_header_right" @click="HomeSearch">
            <img
              src="../assets/home/icon_search.png"
              alt=""
              class="icon_search"
            />
            <input
              type="text"
              placeholder="搜索城市，景区"
              class="input_search"
            />
          </div>
        </div>
        <!-- 轮播 -->
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>
            <img src="../assets/home/banner.png" alt="" class="swipe-item" />
          </van-swipe-item>
          <van-swipe-item>
            <img src="../assets/home/banner.png" alt="" class="swipe-item" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <!-- 金刚区 -->
    <div class="Jingang_box">
      <div
        class="Jingang_item"
        v-for="(item, index) in insectList"
        :key="index"
        @click="onClickUrl(item.url)"
      >
        <img :src="item.img" alt="" class="Jingang-icon" />
        <span class="Jingang_text">{{ item.text }}</span>
      </div>
    </div>

    <!-- 新人专享乐游小宝 -->
    <div class="personality_box">
      <div class="personality_left">
        <div class="news_pel">新人专区</div>
        <div class="personality_scroll">
          <div class="personality_item">
            <div class="personality_price"><span class="demo">￥</span>30</div>
            <div class="personality_tiyan">畅听体验</div>
            <div class="personality_use">去使用</div>
          </div>
          <div class="personality_item">
            <div class="personality_price"><span class="demo">￥</span>30</div>
            <div class="personality_tiyan">畅听体验</div>
            <div class="personality_use">去使用</div>
          </div>
          <div class="personality_item">
            <div class="personality_price"><span class="demo">￥</span>30</div>
            <div class="personality_tiyan">畅听体验</div>
            <div class="personality_use">去使用</div>
          </div>
          <div class="personality_item">
            <div class="personality_price"><span class="demo">￥</span>30</div>
            <div class="personality_tiyan">畅听体验</div>
            <div class="personality_use">去使用</div>
          </div>
        </div>
      </div>
      <div class="personality_right">
        <div class="get_in">点击进入</div>
        <div class="machinery_box">
          <div class="machinery_to">HI~</div>
          <div class="machinery_title">我是乐游小宝</div>
          <div class="machinery_center">您的专属旅游顾问</div>
        </div>
        <img src="../assets/home/jiqiren.png" alt="" class="jiqiren_icon" />
      </div>
    </div>

    <IndexItemVue></IndexItemVue>
  </div>
</template>

<script>
import IndexItemVue from "../views/home/components/indexItem.vue";
export default {
  components: {
    IndexItemVue,
  },
  data() {
    return {
      insectList: [
        {
          img: require("../assets/home/icon_bangdan.png"),
          text: "推荐榜单",
          url: "/nominate",
        },
        {
          img: require("../assets/home/icon_yanxue.png"),
          text: "研学游",
        },
        {
          img: require("../assets/home/icon_haiwai.png"),
          text: "海外景区",
          url: "/overseas",
        },
        {
          img: require("../assets/home/icon_luying.png"),
          text: "露营基地",
          url: "/bivouac",
        },
        {
          img: require("../assets/home/icon_city.png"),
          text: "城市观光",
          url: "/cityTour",
        },
      ],
    };
  },
  mounted() {
    if (window.navigator.onLine) {
      console.log("网络正常！");
    } else {
      console.log("网络中断！");
    }
  },
  methods: {
    checkNetworkStatus() {
      const connection = navigator.connection;
      if (
        connection.effectiveType === Connection.EFFECTIVE_TYPE.CELL_2G ||
        connection.effectiveType === Connection.EFFECTIVE_TYPE.CELL_3G ||
        connection.effectiveType === Connection.EFFECTIVE_TYPE.CELL_4G
      ) {
        console.log("网络连接正常");
      } else {
        console.log("网络连接丢失");
      }
    },
    HomeSearch() {
      this.$router.push("/homeSearch");
    },
    onClickUrl(newsUrl) {
      this.$router.push(newsUrl);
    },
    navCity() {
      // this.$router.push("/cityTour");
      window.location.href = "https://changflowh5.nc10086.com/videoSlide";
    },
  },
};
</script>

<style scoped lang="less">
.container_box {
  // background: #f5f5f5;
  // background: linear-gradient(180deg, #fff 60%, #f5f5f5 100%);
}
.hader_stick {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
  height: 474px;
  background: #fff;
}
.container-header-icon {
  background: url(../assets/home/home_header.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 474px;
  overflow: hidden;
}
.location_header {
  display: flex;
  align-items: center;
  margin: 90px 40px 0 34px;
}
.location_icon {
  width: 20px;
  height: 24px;
}

.location_text {
  height: 40px;
  font-weight: 700;
  font-size: 32px;
  color: #0d0c0c;
  line-height: 40px;
  margin-left: 18px;
}
.location_header_left {
  display: flex;
  align-items: center;
}
.location_header_right {
  height: 64px;
  background: #ffffff;
  border-radius: 36px 36px 36px 6px;
  flex: 1;
  margin-left: 24px;
  display: flex;
  align-items: center;
}
.icon_search {
  width: 32px;
  height: 34px;
  margin-left: 20px;
}
.input_search {
  height: 40px;
  font-weight: 400;
  font-size: 28px;
  color: #333;
  line-height: 40px;
  flex: 1;
  margin: 0 20px;
  border: none;
  background: transparent;
}
.input_search::placeholder {
  color: #b6b6b6;
  padding-left: 6px;
}
.swipe-item {
  height: 280px;
  width: 100%;
}
.my-swipe {
  margin: 20px 30px 0;
}
.Jingang_box {
  margin: 514px 30px 38px;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Jingang-icon {
  width: 122px;
  height: 122px;
  position: absolute;
  top: -50px;
  right: 18px;
}
.Jingang_text {
  height: 32px;
  font-weight: 500;
  font-size: 22px;
  color: #373339;
  line-height: 32px;
  margin-top: 52px;
}
.Jingang_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 124px;
  height: 86px;
  background: #ebfdf7;
  border-radius: 26px;
  position: relative;
}
.Jingang_item:nth-child(2) {
  background: #e8f6fc;
}
.Jingang_item:nth-child(3) {
  background: #fef3e2;
}
.Jingang_item:nth-child(4) {
  background: #f9f2ff;
}
.Jingang_item:nth-child(5) {
  background: #fef3e2;
}
.personality_box {
  margin: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid red; */
}
.personality_left {
  width: 330px;
  height: 232px;
  background: linear-gradient(95deg, #fdf6eb 0%, #fdf6eb 100%);
  border-radius: 20px;
  border: 2px solid #fde4ba;
}
.personality_right {
  background: url(../assets/home/machine.png) no-repeat;
  background-size: 100% 100%;
  width: 336px;
  height: 232px;
  position: relative;
  display: flex;
}
.news_pel {
  background: url(../assets/home/news_pel.png) no-repeat;
  background-size: 100% 100%;
  width: 262px;
  height: 77px;
  text-align: center;
  font-size: 28px;
  color: #fff;
  margin-left: 36px;
}
.personality_item {
  height: 152px;
  background: #ffffff;
  border-radius: 20px;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 121px; /* 使每个项目宽度固定 */
}
.personality_use {
  background: #fe6a38;
  border-radius: 17px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  width: 92px;
  height: 34px;
  /* margin-left: 20px; */
}
.personality_tiyan {
  height: 28px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 20px;
  color: #fe6a38;
  line-height: 28px;
  margin: 4px 0 14px;
}
.personality_price {
  height: 56px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 40px;
  color: #fe6a38;
  line-height: 56px;
  text-align: center;
}
.demo {
  font-size: 22px;
}
.personality_scroll {
  width: 100%;
  white-space: nowrap; /* 防止换行 */
  display: flex;
  align-items: center;
  overflow-x: auto;
}
.personality_scroll::-webkit-scrollbar {
  display: none;
}
.get_in {
  position: absolute;
  height: 28px;
  font-size: 20px;
  color: #ffffff;
  line-height: 28px;
  right: 14px;
  top: 14px;
}
.machinery_to {
  height: 50px;
  font-weight: 600;
  font-size: 36px;
  color: #0d0c0c;
  line-height: 50px;
}
.machinery_title {
  height: 42px;
  font-weight: 600;
  font-size: 34px;
  color: #0d0c0c;
  line-height: 42px;
  margin: 10px 0 6px;
}
.machinery_center {
  height: 28px;
  font-weight: 600;
  font-size: 22px;
  color: #0d0c0c;
  line-height: 28px;
}
.machinery_box {
  margin: 30px 0 0 24px;
}
.jiqiren_icon {
  width: 101px;
  height: 130px;
  margin-top: 70px;
}
</style>
