import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import wxAuth from '../views/wxAuth.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'index',
    component: Index  //首页
  },
  {
    path: '/wxAuth',
    name: 'wxAuth',
    component: wxAuth  // 微信支付授权页面
  }, 
  {
    path: '/homeSearch',
    name: 'homeSearch',
    component: () => import('../views/home/homeSearch.vue') // 景区搜索
  },
  {
    path: '/cityTour',
    name: 'cityTour',
    component: () => import('../views/home/cityTour.vue') //城市观光
  },
   {
    path: '/nominate',
    name: 'nominate',
    component: () => import('../views/home/nominate.vue') //推荐榜单
  },
    {
    path: '/childTravel',
    name: 'childTravel',
    component: () => import('../views/recommed/childTravel.vue') //亲子游
  },
    {
    path: '/childTraveldetails',
    name: 'childTraveldetails',
    component: () => import('../views/recommed/childTraveldetails.vue') //亲子游详情
  },
    {
    path: '/publish',
    name: 'publish',
    component: () => import('../views/recommed/publish.vue') //留言发布
  },
     {
    path: '/grassCamp',
    name: 'grassCamp',
    component: () => import('../views/recommed/grassCamp.vue') //草坪露营
  },
     {
    path: '/merchants',
    name: 'merchants',
    component: () => import('../views/recommed/merchants.vue') //商家入驻
  },
  {
    path: '/leaveWord',
    name: 'leaveWord',
    component: () => import('../views/recommed/leaveWord.vue') //露营基地查看留言
  },
  {
    path: '/announce',
    name: 'announce',
    component: () => import('../views/recommed/announce.vue') // 露营基地发布留言
  },
  {
    path: '/overseas',
    name: 'overseas',
    component: () => import('../views/recommed/overseas.vue') // 海外
  },
  {
    path: '/Funuide',
    name: 'Funuide',
    component: () => import('../views/recommed/Funuide.vue') // 好玩攻略
  },
  {
    path: '/manual',
    name: 'manual',
    component: () => import('../views/recommed/manual.vue') // 避坑指南
  },
   {
    path: '/periphery',
    name: 'periphery',
    component: () => import('../views/recommed/periphery.vue') // 乐游小宝周边
  },
   {
    path: '/placeInterest',
    name: 'placeInterest',
    component: () => import('../views/interest/placeInterest.vue') // 旅游景点
  },
   {
    path: '/Postmessage',
    name: 'Postmessage',
    component: () => import('../views/interest/Postmessage.vue') // 景点留言发布
  },
    {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/interest/pay.vue') // 会员购买
  },
     {
    path: '/Campingbase',
    name: 'Campingbase',
    component: () => import('../views/interest/Campingbase.vue') // 露营基地
  },
  {
    path: '/bivouac',
    name: 'bivouac',
    component: () => import('../views/interest/bivouac.vue') // 首页露营基地
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
})

export default router
